// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';
import { BuilderProvider } from '@builder/component-library';
import { Outlet, useLocation } from 'react-router-dom';
import { WebRoutesGenerator } from '../../components/src/NavigationRouteWrapper/Web';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import Gettyimagesintegration2 from "../../blocks/gettyimagesintegration2/src/Gettyimagesintegration2";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Analytics from "../../blocks/analytics/src/Analytics";
import Location from "../../blocks/location/src/Location";
import Signup3 from "../../blocks/signup3/src/Signup3";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import SocialMediaAccountLogin from "../../blocks/social-media-account/src/SocialMediaAccountLogin";
import SocialMediaAccountRegistration from "../../blocks/social-media-account/src/SocialMediaAccountRegistration";
import VideoLibrary from "../../blocks/videolibrary/src/VideoLibrary";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import PasswordStrength from "../../blocks/passwordstrength/src/PasswordStrength";
import AddressManagement from "../../blocks/addressmanagement/src/AddressManagement";
import AddAddress from "../../blocks/addressmanagement/src/AddAddress";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import Maps from "../../blocks/maps/src/Maps";
import CreateComment from "../../blocks/comments/src/CreateComment";
import VideoEmbeddingYoutube from "../../blocks/videoembeddingyoutube/src/VideoEmbeddingYoutube";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import NavigationMenu from "../../blocks/navigationmenu/src/NavigationMenu";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Appointments from "../../blocks/appointmentmanagement/src/Appointments";
import AddAppointment from "../../blocks/appointmentmanagement/src/AddAppointment";
import Settings2 from "../../blocks/settings2/src/Settings2";
import DataStorage from "../../blocks/datastorage/src/DataStorage";
import Employeelogin from "../../blocks/employeelogin/src/Employeelogin";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import ContactsIntegration from "../../blocks/contactsintegration/src/ContactsIntegration";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import AllEvents from "../../blocks/events/src/AllEvents";
import CreateEvent from "../../blocks/events/src/CreateEvent";
import EventDetail from "../../blocks/events/src/EventDetail";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";


const routeMap = {
Gettyimagesintegration2:{
 component:Gettyimagesintegration2,
path:"/Gettyimagesintegration2"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
Location:{
 component:Location,
path:"/Location"},
Signup3:{
 component:Signup3,
path:"/Signup3"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
SocialMediaAccountLogin:{
 component:SocialMediaAccountLogin,
path:"/SocialMediaAccountLogin"},
SocialMediaAccountRegistration:{
 component:SocialMediaAccountRegistration,
path:"/SocialMediaAccountRegistration"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
PasswordStrength:{
 component:PasswordStrength,
path:"/PasswordStrength"},
AddressManagement:{
 component:AddressManagement,
path:"/AddressManagement"},
AddAddress:{
 component:AddAddress,
path:"/AddAddress"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
Maps:{
 component:Maps,
path:"/Maps"},
Comment:{
 component:Comment,
path:"/Comment"},
CreateComment:{
 component:CreateComment,
path:"/CreateComment"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
NavigationMenu:{
 component:NavigationMenu,
path:"/NavigationMenu"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Appointments:{
 component:Appointments,
path:"/Appointments"},
AddAppointment:{
 component:AddAppointment,
path:"/AddAppointment"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
DataStorage:{
 component:DataStorage,
path:"/DataStorage"},
Employeelogin:{
 component:Employeelogin,
path:"/Employeelogin"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
ContactsIntegration:{
 component:ContactsIntegration,
path:"/ContactsIntegration"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
AllEvents:{
 component:AllEvents,
path:"/AllEvents"},
CreateEvent:{
 component:CreateEvent,
path:"/CreateEvent"},
EventDetail:{
 component:EventDetail,
path:"/EventDetail"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},

  Home: {
    component: HomeScreen,
    path: '/',
    exact: true,
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3',
});

const defaultAnalytics = firebaseAPI.analytics();
defaultAnalytics.logEvent('APP_Loaded');

function App() {
  return (
    <BuilderProvider>
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        <WebRoutesGenerator routeMap={routeMap} />
        <Outlet />
        <AlertBlock />
      </View>
    </BuilderProvider>
  );
}

export default App;